import * as React from "react"
import parse from 'html-react-parser';
import shortid from  "shortid";

const TiltitChallengesSection = (props) => {
    return (
        <section className="case-study-challenges-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="company-info-items">
                            {props.companyInfoList?.map((object, i) => {
                                return (
                                    <div className="item" key={shortid.generate()}>
                                        <p className="p section-title semibold primary-black">{parse(object.title)}</p>
                                        <p className="p section-description">{parse(object.description)}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-15">
                        <div className="company-detail-items">
                            {props.challengesList?.map((object, i) => {
                                return (
                                    <div className="item" key={shortid.generate()}>
                                        <h1 className="h1 section-title">{parse(object.title)}</h1>
                                        <p className="section-description">{parse(object.description)}</p>
                                        <div className="image-block">
                                            <img
                                                src={object.image1x?.sourceUrl}
                                                srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                width={object.image1x?.width}
                                                alt={object.image1x?.altText}
                                            />
                                        </div>
                                        {/* <p className="section-description-2">{parse(object.description2)}</p> */}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TiltitChallengesSection