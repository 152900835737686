import React, {useEffect, useRef, useState} from "react";
import { graphql } from "gatsby";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';

import Layout from "../../components/layout";
import Seo from "../../components/seo";

//section
import TiltitHeroSection from "../sections/caseStudies/tiltit/TiltitHeroSection";
import TiltitChallengesSection from "../sections/caseStudies/tiltit/TiltitChallengesSection";
import TiltitSection1 from "../sections/caseStudies/tiltit/TiltitSection1";
import TiltitSection2 from "../sections/caseStudies/tiltit/TiltitSection2";
import TiltitSection3 from "../sections/caseStudies/tiltit/TiltitSection3";
import TiltitSection4 from "../sections/caseStudies/tiltit/TiltitSection4";
import TiltitSection5 from "../sections/caseStudies/tiltit/TiltitSection5";
import TiltitSection6 from "../sections/caseStudies/tiltit/TiltitSection6";
import TestimonialSection from "../sections/TestimonialSection";

const Tiltit = ({data}) => {
    const mainData = data.allWpPage.nodes[0].caseStudyTiltitPageACF;
    const sectionRef = useRef();
    const [bgTransition, setBgTransition] = useState('');
    // useEffect(() => {
    //   let unmounted = false;
    //   gsap.registerPlugin(ScrollTrigger)
    //   setTimeout(() => {
    //     if (!unmounted) {
    //       gsap.set(sectionRef.current, {
    //         scrollTrigger: {
    //           trigger: '.unikube-case-study .section-5',
    //           start: "top 60%",
    //           end: "bottom 60%",
    //           ease: "ease",
    //           scrub: true,
    //           markers: false,
    //           onEnter: () => setBgTransition('unikube-bg'),
    //           onLeave: () => setBgTransition(''),
    //           onEnterBack: () => setBgTransition('unikube-bg'),
    //           onLeaveBack: () => setBgTransition(''),
    //         }
    //       });
    //     }
    //   }, 1000);
  
    //   return () => {
    //     unmounted = true;
    //   }
    // }, []);
    return(
        <Layout headerClass="white-header">
            <Seo title="Tiltit" />
            <div className={"tiltit-case-study page-wrapper " + bgTransition} id="tiltit-casestudy-page-wrapper" ref={sectionRef}>
                <TiltitHeroSection 
                    preTitle={parse(mainData.tiltitHeroSectionPretitle)}
                    mainTitle={parse(mainData.tiltitHeroSectionTitle)}
                    image1x={
                      {
                        sourceUrl: mainData.tiltitHeroSectionImage1x.sourceUrl,
                        altText: mainData.tiltitHeroSectionImage1x.altText,
                        width: mainData.tiltitHeroSectionImage1x.width,
                      }
                    }
                    image2x={
                      {
                        sourceUrl: mainData.tiltitHeroSectionImage2x.sourceUrl,
                        altText: mainData.tiltitHeroSectionImage2x.altText,
                        width: mainData.tiltitHeroSectionImage2x.width,
                      }
                    }
                    mobileImage={
                      {
                        sourceUrl: mainData.tiltitHeroSectionMobileImage.sourceUrl,
                        altText: mainData.tiltitHeroSectionMobileImage.altText,
                        width: mainData.tiltitHeroSectionMobileImage.width,
                      }
                    }
                />
                <TiltitChallengesSection 
                  companyInfoList={mainData.tiltitChallengeSectionCompanyInfoList}
                  challengesList={mainData.tiltitChallengeSectionChallengesList}
                />
                <TiltitSection1 
                  mainTitle={parse(mainData.tiltitSection1Title)}
                  mainDescription={parse(mainData.tiltitSection1Description)}
                  image1x={
                    {
                      sourceUrl: mainData.tiltitSection1Image1x.sourceUrl,
                      altText: mainData.tiltitSection1Image1x.altText,
                      width: mainData.tiltitSection1Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tiltitSection1Image2x.sourceUrl,
                      altText: mainData.tiltitSection1Image2x.altText,
                      width: mainData.tiltitSection1Image2x.width,
                    }
                  }
                />
                <TiltitSection2 
                  mainTitle={parse(mainData.tiltitSection2Title)}
                  mainDescription={parse(mainData.tiltitSection2Description)}
                  image1x={
                    {
                      sourceUrl: mainData.tiltitSection2Image1x.sourceUrl,
                      altText: mainData.tiltitSection2Image1x.altText,
                      width: mainData.tiltitSection2Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tiltitSection2Image2x.sourceUrl,
                      altText: mainData.tiltitSection2Image2x.altText,
                      width: mainData.tiltitSection2Image2x.width,
                    }
                  }
                />
                <TiltitSection3
                  mainTitle1={parse(mainData.tiltitSection3MainTitle)} 
                  mainTitle={parse(mainData.tiltitSection3Title)}
                  mainDescription={parse(mainData.tiltitSection3Description)}
                  image1x={
                    {
                      sourceUrl: mainData.tiltitSection3Image1x.sourceUrl,
                      altText: mainData.tiltitSection3Image1x.altText,
                      width: mainData.tiltitSection3Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tiltitSection3Image2x.sourceUrl,
                      altText: mainData.tiltitSection3Image2x.altText,
                      width: mainData.tiltitSection3Image2x.width,
                    }
                  }
                />
                <TiltitSection4 
                  mainTitle={parse(mainData.tiltitSection4Title)}
                  mainDescription={parse(mainData.tiltitSection4Description)}
                  image11x={
                    {
                      sourceUrl: mainData.tiltitSection4Image11x.sourceUrl,
                      altText: mainData.tiltitSection4Image11x.altText,
                      width: mainData.tiltitSection4Image11x.width,
                    }
                  }
                  image12x={
                    {
                      sourceUrl: mainData.tiltitSection4Image12x.sourceUrl,
                      altText: mainData.tiltitSection4Image12x.altText,
                      width: mainData.tiltitSection4Image12x.width,
                    }
                  }
                  image21x={
                    {
                      sourceUrl: mainData.tiltitSection4Image21x.sourceUrl,
                      altText: mainData.tiltitSection4Image21x.altText,
                      width: mainData.tiltitSection4Image21x.width,
                    }
                  }
                  image22x={
                    {
                      sourceUrl: mainData.tiltitSection4Image22x.sourceUrl,
                      altText: mainData.tiltitSection4Image22x.altText,
                      width: mainData.tiltitSection4Image22x.width,
                    }
                  }
                />
                <TiltitSection5 
                  mainTitle={parse(mainData.tiltitSection5Title)}
                  image1x={
                    {
                      sourceUrl: mainData.tiltitSection5Image1x.sourceUrl,
                      altText: mainData.tiltitSection5Image1x.altText,
                      width: mainData.tiltitSection5Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tiltitSection5Image2x.sourceUrl,
                      altText: mainData.tiltitSection5Image2x.altText,
                      width: mainData.tiltitSection5Image2x.width,
                    }
                  }
                />
                <TiltitSection6 
                  mainTitle={parse(mainData.tiltitSection6Title)}
                  image1x={
                    {
                      sourceUrl: mainData.tiltitSection6Image1x.sourceUrl,
                      altText: mainData.tiltitSection6Image1x.altText,
                      width: mainData.tiltitSection6Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tiltitSection6Image2x.sourceUrl,
                      altText: mainData.tiltitSection6Image2x.altText,
                      width: mainData.tiltitSection6Image2x.width,
                    }
                  }
                />
                <TestimonialSection
                  mainTitle={parse(mainData.tiltitTesimonialSectionTitle)}
                  message={parse(mainData.tiltitTesimonialSectionDescription)}
                  name={parse(mainData.tiltitTesimonialSectionName)}
                  designation={parse(mainData.tiltitTesimonialSectionDesignation)}
                  headshot1x={
                    {
                      sourceUrl: mainData.tiltitTesimonialSectionHeadshot1x.sourceUrl,
                      altText: mainData.tiltitTesimonialSectionHeadshot1x.altText,
                      width: mainData.tiltitTesimonialSectionHeadshot1x.width,
                    }
                  }
                  headshot2x={
                    {
                      sourceUrl: mainData.tiltitTesimonialSectionHeadshot2x.sourceUrl,
                      altText: mainData.tiltitTesimonialSectionHeadshot2x.altText,
                      width: mainData.tiltitTesimonialSectionHeadshot2x.width,
                    }
                  }
                  logo={
                    {
                      sourceUrl: mainData.tiltitTesimonialSectionLogo.sourceUrl,
                      altText: mainData.tiltitTesimonialSectionLogo.altText,
                      width: mainData.tiltitTesimonialSectionLogo.width,
                    }
                  }
                />
            </div>
        </Layout>
    )
}

export const TiltitQuery = graphql`
{
    allWpPage(filter: {slug: {eq: "tiltit"}}) {
      nodes {
        caseStudyTiltitPageACF {
          tiltitHeroSectionPretitle
          tiltitHeroSectionTitle
          tiltitHeroSectionImage1x {
            altText
            sourceUrl
            width
          }
          tiltitHeroSectionImage2x {
            altText
            sourceUrl
            width
          }
          tiltitHeroSectionMobileImage {
            altText
            sourceUrl
            width
          }
          tiltitChallengeSectionCompanyInfoList {
            title
            description
          }
          tiltitChallengeSectionChallengesList {
            title
            description
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
            description2
          }
          tiltitSection1Title
          tiltitSection1Description
          tiltitSection1Image1x {
            altText
            sourceUrl
            width
          }
          tiltitSection1Image2x {
            altText
            sourceUrl
            width
          }
          tiltitSection2Title
          tiltitSection2Description
          tiltitSection2Image1x {
            altText
            sourceUrl
            width
          }
          tiltitSection2Image2x {
            altText
            sourceUrl
            width
          }
          tiltitSection3MainTitle
          tiltitSection3Title
          tiltitSection3Description
          tiltitSection3Image1x {
            altText
            sourceUrl
            width
          }
          tiltitSection3Image2x {
            altText
            sourceUrl
            width
          }
          tiltitSection4Title
          tiltitSection4Description
          tiltitSection4Image11x {
            altText
            sourceUrl
            width
          }
          tiltitSection4Image12x {
            altText
            sourceUrl
            width
          }
          tiltitSection4Image21x {
            altText
            sourceUrl
            width
          }
          tiltitSection4Image22x {
            altText
            sourceUrl
            width
          }
          tiltitSection5Title
          tiltitSection5Image1x {
            altText
            sourceUrl
            width
          }
          tiltitSection5Image2x {
            altText
            sourceUrl
            width
          }
          tiltitSection6Title
          tiltitSection6Image1x {
            altText
            sourceUrl
            width
          }
          tiltitSection6Image2x {
            altText
            sourceUrl
            width
          }
          tiltitTesimonialSectionTitle
          tiltitTesimonialSectionDescription
          tiltitTesimonialSectionHeadshot1x {
            altText
            sourceUrl
            width
          }
          tiltitTesimonialSectionHeadshot2x {
            altText
            sourceUrl
            width
          }
          tiltitTesimonialSectionName
          tiltitTesimonialSectionDesignation
          tiltitTesimonialSectionLogo {
            altText
            sourceUrl
            width
          }
        }
      }
    }
}
`


export default Tiltit;
